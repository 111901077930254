export const structure = {
  map: {},

  heatmap: {
    // TODO:
    active: false,
    layer: null,
    attribute: null
  },
  fields: {
    // TODO:
    taxi: null
  },
  additional_filters: {
    // TODO:
    taxi: null
  },

  activeLink: null,
  city: null,
  baseModel: null
}

export const nonClearableFields = ['map', 'city']
