export const systemSettings = {
  LOCALE: 'locale',
  ABBR: 'abbr',
  ADDITIONAL_GIS_LAYERS: 'additionalGisLayers',
  CUSTOM_BASELAYERS: 'customBaselayers',
  THEME: 'theme'
}

export const systemSettingsDefault = {
  [systemSettings.LOCALE]: 'ru',
  [systemSettings.ABBR]: false,
  [systemSettings.ADDITIONAL_GIS_LAYERS]: {},
  [systemSettings.CUSTOM_BASELAYERS]: {},
  [systemSettings.THEME]: 'system'
}

export const defaultItsConfig = {
  layersOnMap: {},
  visiblePanels: {
    left: true,
    bottom: true
  },
  filters: {},
  dashboardFilters: {},
  dashboardWidgets: []
}

export const defaultPTConfig = {
  isochrone_analysis: { threshold: null },
  reportConfigs: {}
}

export const defaultMapModulesConfig = {
  activeLayers: [],
  layerStyles: {},
  zIndex: {},
  legendWidgetsOnMap: {}
}

export const mainMapModules = ['gis', 'ksodd', 'modeling', 'digital_twin']

export const serverToFrontModuleRelations = {
  asudd: 'asudd',
  digital_twin: 'digitalTwin',
  dispatch_control: 'emergency',
  efficiency_control: 'itsControl',
  gis: 'gis',
  ksodd: 'ksodd',
  mciud: 'info',
  modeling: 'modeling',
  parking_space: 'parking',
  permissions: 'licenses',
  public_transport: 'publicTransport',
  road_consulting: 'tsodd',
  system: 'system',
  road_works: 'roadWorks',
  traffic_violations: 'pdd'
}
