export const structure = {
  map: {},
  fields: {
    parkings: null,
    parkomats: null,
    terminals: null,
    taxi: null,
    tariffs: null
  },
  city: null,
  heatmap: {
    active: false,
    layer: null,
    attribute: null
  },
  additional_filters: {
    parkomats: null,
    terminals: null,
    taxi: null
  },
  objectCard: null,
  objectEditor: null,
  tariffTypes: null,
  layerNames: {},
  layerStyles: {},
  activeLayers: [],
  isSelectDisabled: false
}

export const nonClearableFields = ['city', 'map', 'fields']
