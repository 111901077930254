export const structure = {
  map: {},
  fields: {
    events: null,
    applications: null,
    cameras: null,
    info_tables: null,
    dits: null,
    meteostations: null
  },
  city: null,
  tables: {},
  filters: {
    cameras: []
  },
  additionalLayers: {
    heatmap: false,
    info_tables: false,
    dits: false,
    meteostations: false
  },
  objectCard: null,
  cameraCardId: null,
  meteostation: false,
  meteostationId: null,
  services: {
    eventTypes: null,
    executors: null
  },
  statuses: {
    cameras: null,
    events: null,
    applications: null
  },
  layerNames: {},
  heatmap: {
    active: false,
    layer: null,
    attribute: null
  },
  additional_filters: {
    events: null,
    applications: null,
    cameras: null,
    info_tables: null,
    dits: null,
    meteostations: null
  }
}

export const nonClearableFields = ['city', 'map']
