export const structure = {
  map: {},
  fields: {
    pvk: null
  },
  activeType: '',
  additional_filters: {
    pvk: null
  }
}

export const nonClearableFields = ['map']
