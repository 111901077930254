export const structure = {
  city: null,
  map: {},
  fields: {
    bus_stop: null
  },
  additional_filters: {
    bus_stop: null
  }
}

export const nonClearableFields = ['city', 'map']
