export const structure = {
  map: {},
  city: null,
  violationId: null,
  cameraId: null,
  fields: {},
  statuses: [],
  heatmap: {
    active: false,
    layer: null,
    attribute: null
  },
  additional_filters: {
    weight: null,
    radars: null
  }
}

export const nonClearableFields = ['city', 'map']
