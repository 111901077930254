import COLORS from 'COLORS'

export const structure = {
  city: null,
  map: {},
  stopId: null,
  routeId: null,
  vehicleId: null,
  activeRoutesId: [],
  activeGroupsId: [],
  currentRoute: null,
  currentRoutes: [],
  currentPointCoordinates: [],
  routeColor: COLORS.mapColors.default,
  fields: {},
  filters: {
    stops: [],
    vehicles: []
  },
  boardsId: null,
  routeStopsIds: null,
  vehicleTypes: null,
  layerNames: {},
  heatmap: {
    active: false,
    layer: null,
    attribute: null
  },
  additional_filters: {
    bus_stop: null,
    vehicles: null,
    info_boards: null
  },
  isochrone_analysis: {
    threshold: null
  },
  reportConfigs: {}
}

export const nonClearableFields = [
  'city',
  'map',
  'isochrone_analysis',
  'reportConfigs'
]
