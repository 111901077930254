export const structure = {
  map: {},
  heatmap: {
    active: false,
    layer: null,
    attribute: null
  },
  fields: {},
  additional_filters: {},
  listSource: null,
  // TODO: refactor: replace card object with card id
  card: null,
  isCreate: false
}

export const nonClearableFields = ['map']
