export const structure = {
  city: null,
  map: {},
  filters: {
    orders: [],
    vehicles: [],
    quarterlyApps: [],
    quarterlyWorkers: []
  },
  sort_type: {
    orders: null,
    vehicles: null,
    quarterlyApps: null,
    quarterlyWorkers: null
  },
  additional_filters: {
    orders: null,
    vehicles: null
  },
  tables: {},
  heatmap: {
    active: false,
    layer: null,
    attribute: null
  },
  fields: {
    quarterlyOrders: null
  },
  services: {
    statuses: null,
    vehicle_types: null,
    quarterly_form_rules: null
  },

  // Router
  routerCard: null,

  // Orders
  orderFields: null,
  orderCardId: null,
  form_rules: null,

  // Vehicles
  showVehicles: false,
  showStops: false,
  vehiclesSidebar: false,
  vehicleCardId: null,

  // Quarterly
  showQuarterly: false,
  quarterlySidebar: false,
  quarterlyAppEditorId: null,
  quarterlyWorkerCardId: null,
  quarterlyWorkerTrack: null,

  // Layer names for popup list
  layerNames: {},

  // Geofences
  geofenceEditorCardId: null,

  // While load data is tree show loader
  loadingTreeNodeId: null,
  // For check changes when close editor
  hasObjectChanges: false
}

export const nonClearableFields = ['city', 'map']
